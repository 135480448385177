import React from "react";
import { Grid, Icon, Button, TextField, Box, Typography, Stack, Fab } from "@mui/material";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { GrShop } from 'react-icons/gr'
import { FaShoppingBag } from 'react-icons/fa'
import { makeStyles } from '@mui/styles';
import clsx from "clsx";
import ReactPlayer from "react-player"
// import { useNavigate, useLocation }  from "react-router-dom";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  introWrapper: {
    padding: "1rem 0px !important",
    overflow: "visible !important",

    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0 !important",
      textAlign: "center",
      "& .list": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
  },
  title: {
    textShadow: "0 0px 0px rgba(0, 0, 0, 0.22)",
    // fontSize: '18px',
  },
}));

const ProductFeature = ({label}: {label:string})=>{
  return (
    <Stack direction='column'>
      <Stack direction='row' padding='8px'>
        <Icon className="mr-2" color="secondary" >
          check
        </Icon>
        <Typography textAlign='left'>
          {label}
        </Typography>
      </Stack>
    </Stack>
  )
}

const Intro3 = () => {
  const classes = useStyles();
  // const navigate = useNavigate()

  return (
    <section className="section" id="intro3">
      <div className={classes.introWrapper}>
        <div className="container">
          <Grid container spacing={0} justify="center" alignItems="center">
            <Grid item md={6}>
              {
                // <h1 className={clsx("text-48 mb-6 text-secondary", classes.title)}>
                //   CocoLink
                // </h1>
              }
              <Box sx={{width:'350px'}}>
                <img className="w-full" src="./icons/banner-4.png" alt="" />
              </Box>
              <h2 className={clsx("text-38 mb-6 text-secondary", classes.title)}>
                CocoLink - Link our community
              </h2>
              <div className="text-22 mt-10 mb-5">
                <h3 className={clsx("text-38 text-muted", classes.title)}>
                Groupbuy in WhatsApp group
                </h3>
              </div>
              <ProductFeature label='Simple , skip troublesome of text editing, JieLong-接龙 enabled' />
              <ProductFeature label='Console for host / group owner, manage orders easily' />
              <ProductFeature label='Groupbuy features out of the box, save time, easiler life' />
              <ProductFeature label='Passwordless (SMS OTP), both host and user account' />

              {
                // <TextField
                //   className="max-w-450"
                //   variant="outlined"
                //   size="small"
                //   placeholder="Your Email:"
                //   fullWidth
                //   InputProps={{
                //     style: {
                //       borderRadius: 300,
                //       padding: "2px 3px",
                //       paddingLeft: "0.75rem",
                //       background: "rgba(255,255,255,0.87)",
                //     },
                //     endAdornment: (
                //       <Button className="bg-secondary rounded text-13 px-7 py-11px">
                //         <Icon fontSize="small">flight_takeoff</Icon>
                //         <span className="ml-2">SUBSCRIBE</span>
                //       </Button>
                //     ),
                //   }}
                // />
              }

            </Grid>
            <Grid item md={2} padding='20px' xs='auto' sx={{justify:'center', }}>
              <Stack alignItems="center" direction='row'>
                  <ReactPlayer
                    url="https://vimeo.com/735373101"
                    width="180px"
                    volume={0}
                    muted={true}
                    playing={true}
                    loop={true}
                    playsinline={true}
                    ali
                  />
              </Stack>
            </Grid>
            <Grid item md={4} sx={{padding:'0px', paddingLeft:'0px'}}>
                <img
                  className="w-full"
                  src="./assets/images/appsample-3.jpg"
                  alt=""
                />
            </Grid>
            <Grid item md={6} >
                  <Fab variant="extended" size="medium" color="secondary" sx={{paddingY: '20px', marginY:'20px'}}
                    onClick={()=>{
                      window.location.href = 'https://host.cocolink.cc/#/signup'
                    }}
                  >
                    <Icon className="mr-4">flight_takeoff</Icon>
                    Host Sign Up
                  </Fab>
                  &nbsp;&nbsp;&nbsp;
                  <Fab variant="extended" size="medium" color="primary"
                    onClick={async()=>{
                      window.location.href = 'https://q.cocolink.cc/register'
                    }}
                  >
                    {
                      // <ShoppingBasketIcon sx={{paddingRight: '10px'}}/>
                    }
                    <FaShoppingBag size='20px'/>
                    &nbsp;&nbsp;Shopper Register
                  </Fab>
            </Grid>
            <Grid item md={8}>
            {
              // <>
              // <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfcY3M4auofLRQBudJ5XFmbbltjuA2FqMhhk17YVgV7MNRrlg/viewform?embedded=true"
              //   width="466" height="450" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
              // </>
            }
            </Grid>
          </Grid>

        </div>
      </div>
    </section>
  );
};

export default Intro3;
