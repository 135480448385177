import React, { useState, useEffect } from "react";
import { debounce, classList } from "../utils";
import { Icon, IconButton, useMediaQuery, Fab } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';

import { NavLink }      from "react-router-dom";
import { useConfirm }   from 'material-ui-confirm';
import ScrollTo from "./common/ScrollTo";

import { useTheme } from '@mui/material/styles';

const TopBar3 = (props) => {
  const [isTop, setIsTop] = useState(true);
  const [isClosed, setIsClosed] = useState(true);
  const confirm = useConfirm()

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let scrollableElement = document.querySelector(".scrollable-content");
  if (!scrollableElement) scrollableElement = window;

  let handleScrollRef = null;
  let toggleIcon = isClosed ? "menu" : "close";

  const handleScroll = () => {
    return debounce(({ target: { scrollTop } }) => {
      let isCurrentTop = scrollTop < 100 || scrollableElement.scrollY < 100;
      setIsTop(isCurrentTop);
    }, 20);
  };

  handleScrollRef = handleScroll();

  const close = () => {
    setIsClosed(false);
  };

  useEffect(() => {
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScrollRef);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScrollRef);
      }
    };
  }, [scrollableElement, handleScrollRef]);

  return (
    <section
      className={classList({
        header: true,
        "bg-transparent": isTop && !isMobile,
        "header-fixed": !isTop,
        closed: isClosed,
      })}
    >
      <div className="container header-container">
        <div className="brand">
          <img src="./icons/banner-4.png" alt="" />
        </div>
        <ul className="navigation">
          <li>
            <ScrollTo to="intro3" onScroll={close}>
              Home
            </ScrollTo>
          </li>
          {
          // <li>
          //   <ScrollTo to="service3" onScroll={close}>
          //     Service
          //   </ScrollTo>
          // </li>
          }
          <li>
            <ScrollTo to="service5" onScroll={close}>
              Features
            </ScrollTo>
          </li>
          {
            // <li>
            //   <NavLink to="/demos">Demos</NavLink>
            // </li>

          }
          {

            // <li>
            //   <ScrollTo to="pricing1" onScroll={close}>
            //     Pricing
            //   </ScrollTo>
            // </li>
            // <li>
            //   <ScrollTo to="contact1" onScroll={close}>
            //     Contact
            //   </ScrollTo>
            // </li>
          }
        </ul>
        <div className="m-auto" />
        <Fab
          variant="extended"
          size="medium"
          color="secondary"
          aria-label="Buy"
          className=""
          onClick={async()=>{
            // await confirm({
            //     title: 'Redirecting to request form ',
            //     description: 'We are in the prelaunch phase, confirm to submit a host account request',
            //   }).then(() => {
            //     window.open('https://docs.google.com/forms/d/e/1FAIpQLSfcY3M4auofLRQBudJ5XFmbbltjuA2FqMhhk17YVgV7MNRrlg/viewform?usp=sf_link',
            //       '_blank');
            //
            //   }).catch(() => { /* ... */ });
            window.location.href='https://www.facebook.com/profile.php?id=100084267871131'
          }}
        >
          <FacebookOutlinedIcon color='primary'/>
          Facebook
        </Fab>
        <IconButton
          className="header__toggle"
          sx={{height:'40px'}}
          onClick={() => {
            setIsClosed(!isClosed);
          }}
        >
          <Icon>{toggleIcon}</Icon>
        </IconButton>
      </div>
    </section>
  );
};

export default TopBar3;
