import React, { useEffect } from "react";
import { scrollTo } from "../utils";
// import Service3 from "./sections/Services3";
// import Service4 from "./sections/Services4";
// import Service5 from "./sections/Services5";
// import Service6 from "./sections/Services6";
// import Service7 from "./sections/Services7";
// import Testimonial3 from "./sections/Testimonial3";

import TopBar3 from "./TopBar3";
import Intro3 from "./Intro3";
import Footer1 from "./Footer1";

import CallToAction2 from "./sections/CallToAction2";
import Pricing1 from "./sections/Pricing1";
// import Contact1 from "./sections/Contact1";

const Landing3 = () => {
  useEffect(() => {
    scrollTo("root");
  }, [scrollTo]);

  return (
    <div className="landing">
      <TopBar3 />
      <Intro3 />
      {
        // <Service3 />
        // <Service4 />
        // <Service5 />
        // <Service6 />
        // <Service7 />
        // <Testimonial3 />
        // <CallToAction2 />
        // <Pricing1 />
      }
      <Footer1 />
    </div>
  );
};

export default Landing3;
