import React from "react";
import {
  Route,
  Switch,
  HashRouter as Router,
  Redirect
} from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';
import { ConfirmProvider }  from 'material-ui-confirm';
import Scrollbar from "react-perfect-scrollbar";
import { Theme } from "./theme";

import "react-perfect-scrollbar/dist/css/styles.css";
import GlobalCss from "./styles/jss/GlobalCss";

import Landing from "./home/Landing";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <ConfirmProvider>
        <GlobalCss>
          <Scrollbar
            className="h-full-screen scrollable-content"
            option={{ suppressScrollX: true }}
          >
             <Router basename="/">
              <Switch>
                <Route path="/" exact component={Landing}  />
                <Redirect from="*" to="/" />
                {/* <Route component={Error} /> */}
              </Switch>
            </Router>
          </Scrollbar>
        </GlobalCss>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

export default App;
