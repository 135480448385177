import React from "react";

import { Grid, Button, Icon } from "@mui/material";
import { makeStyles } from '@mui/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  footerSection: {
    "& h4:after": {
      content: '" "',
      position: "absolute",
      bottom: -8,
      left: 0,
      height: 2,
      width: 64,
      background: palette.secondary.main,
    },
  },
}));

const Footer1 = () => {
  const classes = useStyles();

  return (
    <div className={clsx("bg-light-dark", classes.footerSection)} id="footer1">
      <div className="container">
        <Grid container>
          <Grid item lg={4} md={4} sm={12}>
            <div className="p-8 h-full elevation-z3">
              <h4 className="text-20 mb-6 relative">About Us</h4>
              <p className="text-inherit">
                We are Singapore base startup, we build tools on internet to strengthen our community
              </p>
              {
                // <Button variant="contained" color="secondary">
                //   Contact Us
                // </Button>
              }
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={12}>
            <div className="p-8 h-full elevation-z3">
              <h4 className="text-20 mb-6 relative">Contact</h4>
              <div className="px-4 mt-8 flex items-center mx--4">
                <WhatsAppIcon color='secondary'/>
                <div className="pl-4">
                  <a href='https://wa.me/6598309078' >
                  <h5 className="m-0 p-0 text-16">Whatsapp</h5>
                  <p className="m-0 p-0 text-inherit">
                    Timo Tan
                  </p>
                  </a>
                </div>
              </div>
              <div className="px-4 my-8 flex items-center mx--4">
                <Icon className="text-secondary">mail</Icon>
                <div className="pl-4">
                  <h5 className="m-0 p-0 text-16">Email</h5>
                  <p className="m-0 p-0 text-inherit">admin@cocolink.cc</p>
                </div>
              </div>
              {
                // <div className="px-4 mt-8 flex items-center mx--4">
                //   <Icon className="text-secondary">location_on</Icon>
                //   <div className="pl-4">
                //     <h5 className="m-0 p-0 text-16">Adress</h5>
                //     <p className="m-0 p-0 text-inherit">
                //       Topoban, Akhalia Sylhet 3114, BD
                //     </p>
                //   </div>
                // </div>
              }
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={12}>
            <div className="p-8 h-full elevation-z3">
              <h4 className="text-20 mb-6 relative">Disclaimer</h4>
              <p className="text-inherit">
                Copyright © cocolink.cc 2022
              </p>
              {
                // <div className="mt-8">
                //   <a href="#linkedin" className="px-2">
                //     <img
                //       className="h-24 w-24"
                //       src="./assets/images/social-linkedin.png"
                //       alt=""
                //     />
                //   </a>
                //   <a href="#twitter" className="px-2">
                //     <img
                //       className="h-24 w-24"
                //       src="./assets/images/social-twitter.png"
                //       alt=""
                //     />
                //   </a>
                //   <a href="#facebook" className="px-2">
                //     <img
                //       className="h-24 w-24"
                //       src="./assets/images/social-facebook.png"
                //       alt=""
                //     />
                //   </a>
                // </div>
              }
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Footer1;
